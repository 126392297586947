import { useTranslation } from "react-i18next";

import ButtonDX from "../controls/buttondx";
import { useState } from "react";
import LoadingButtonDX from "../controls/loadingbuttondx";
import ConfirmModal from "../alerts/confirmmodal";
import GridDX from "../layout/griddx";
import TypographyDX from "../layout/typographydx";
import { Button, Chip } from "@mui/material";
import { PrintOutlined } from "@mui/icons-material";
import {
  inboundRequestStatusColor,
  leadStatusColor,
  quotationStatusColor,
} from "../../shared/globals";
import TypeTranslator from "../../shared/typetranslator";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import customerLogo from "../../assets/customer_logo.png";
import "../../assets/fonts/arabic-font-normal.js"; //   // Import the custom font
import { NumberFormat } from "xlsx";
import { useAuthContext } from "../../context/authcontext";

const AddEditEmbeddedModal = (props: any) => {
  const { userData } = useAuthContext();
  const { t, i18n } = useTranslation();

  const languageIsEn = i18n.language === "en";
  const {
    isChanged,
    handleClose,
    cancelLabel,
    title,
    onSaveClick,
    isSaving,
    saveLabel,
    isPrintable = false,
    showSaveAndSubmit = false,
    quotationStatus,
    leadRequestStatus,
    leadRequestType,
    inboundRequestStatus,
    onAcceptClick,
    onDeclineClick,
    onProcessClick,
    isProcessInitiated,
    initiateProcess,
    isSkuDataModified,
    onRejectModifications,
    onApproveModifications,
    outboundRequestStatus,
    markAsJunk,
    markAsLost,
  } = props;
  const [open, setOpen] = useState(false);

  const onClose = () => {
    if (isChanged) setOpen(true);
    else handleClose();
  };
  const {
    getQuotationStatusValue,
    getLeadRequestStatusValue,
    getInboundRequestStatusValue,
  } = TypeTranslator();
  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
      <GridDX
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2 }}
      >
        <TypographyDX variant="h5" fontWeight="bold">
          {title}
          {quotationStatus && (
            <Chip
              sx={{
                py: 0,
                ml: 1,
                backgroundColor: quotationStatusColor.regular[quotationStatus],
                color: "#fff",
              }}
              label={getQuotationStatusValue(quotationStatus)}
            />
          )}
          {leadRequestStatus && (
            <Chip
              sx={{
                py: 0,
                ml: 1,
                backgroundColor: leadStatusColor.regular[leadRequestStatus],
                color: "#fff",
              }}
              label={getLeadRequestStatusValue(leadRequestStatus)}
            />
          )}
          {(inboundRequestStatus || outboundRequestStatus) && (
            <Chip
              sx={{
                py: 0,
                ml: 1,
                backgroundColor:
                  inboundRequestStatusColor.regular[
                    inboundRequestStatus || outboundRequestStatus
                  ],
                color: "#fff",
              }}
              label={getInboundRequestStatusValue(
                inboundRequestStatus || outboundRequestStatus
              )}
            />
          )}
        </TypographyDX>

        <GridDX item xs={7.2} justifyContent="flex-end" sx={{ my: 2, gap: 8 }}>
          {isPrintable && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "#fff" }}
              onClick={props.handleGeneratePDF}
              startIcon={<PrintOutlined />}
            >
              {t("Print")}
            </Button>
          )}
          <ButtonDX
            variant="outlined"
            onClick={onClose}
            sx={{ mb: { xs: 2, sm: "auto" }, maxWidth: 120 }}
          >
            {cancelLabel ?? t("Cancel")}
          </ButtonDX>
          {userData.userType === "S" &&
            leadRequestStatus &&
            leadRequestStatus == 1 && (
              <LoadingButtonDX
                color="warning"
                onClick={markAsJunk}
                loading={isSaving}
                sx={{ color: "#fff" }}
              >
                {t("Mark as Junk")}
              </LoadingButtonDX>
            )}
          {userData.userType === "S" &&
            leadRequestStatus &&
            leadRequestStatus == 1 && (
              <LoadingButtonDX
                color="error"
                onClick={markAsLost}
                loading={isSaving}
                sx={{ color: "#fff" }}
              >
                {t("Mark as Lost")}
              </LoadingButtonDX>
            )}
          {!quotationStatus &&
          !leadRequestType &&
          !inboundRequestStatus &&
          !outboundRequestStatus ? (
            <LoadingButtonDX
              color="primary"
              onClick={onSaveClick}
              loading={isSaving}
              sx={{ maxWidth: 120 }}
            >
              {saveLabel ?? t("Save")}
            </LoadingButtonDX>
          ) : (
            ((quotationStatus && quotationStatus == 1) ||
              leadRequestType == 2 ||
              (inboundRequestStatus && inboundRequestStatus == 1) ||
              (outboundRequestStatus && outboundRequestStatus == 1)) && (
              <LoadingButtonDX
                disabled={leadRequestStatus && leadRequestStatus != 1}
                color="primary"
                onClick={onSaveClick}
                loading={isSaving}
                sx={{ maxWidth: 120 }}
              >
                {saveLabel ?? t("Save")}
              </LoadingButtonDX>
            )
          )}

          {((userData.userType == "S" &&
            inboundRequestStatus &&
            inboundRequestStatus == 2) ||
            (userData.userType == "S" &&
              outboundRequestStatus &&
              outboundRequestStatus == 2)) && (
            <LoadingButtonDX
              color="error"
              onClick={onDeclineClick}
              loading={isSaving}
              // loadingPosition="end"
              sx={{ maxWidth: 120 }}
            >
              {t("Decline")}
            </LoadingButtonDX>
          )}
          {((userData.userType == "S" &&
            inboundRequestStatus &&
            inboundRequestStatus == 2) ||
            (userData.userType == "S" &&
              outboundRequestStatus &&
              outboundRequestStatus == 2)) && (
            <LoadingButtonDX
              color="success"
              onClick={onAcceptClick}
              loading={isSaving}
              // loadingPosition="end"
              sx={{ maxWidth: 120, color: "#fff" }}
            >
              {t("Accept")}
            </LoadingButtonDX>
          )}
          {userData.userType === "S" &&
            (inboundRequestStatus === 3 || outboundRequestStatus === 3) &&
            !isProcessInitiated && (
              <LoadingButtonDX
                color="secondary"
                onClick={initiateProcess}
                loading={isSaving}
                sx={{ maxWidth: 120, color: "#fff" }}
              >
                {t("Process")}
              </LoadingButtonDX>
            )}

          {userData.userType == "S" &&
            inboundRequestStatus &&
            inboundRequestStatus == 3 &&
            isProcessInitiated && (
              <LoadingButtonDX
                color="error"
                onClick={() => onProcessClick(9)}
                loading={isSaving}
                // loadingPosition="end"
                sx={{ maxWidth: 120, color: "#fff" }}
              >
                {t("Reject")}
              </LoadingButtonDX>
            )}

          {userData.userType == "S" &&
            inboundRequestStatus &&
            inboundRequestStatus == 3 &&
            isProcessInitiated &&
            !isSkuDataModified && (
              <LoadingButtonDX
                color="success"
                onClick={() => onProcessClick(8)}
                loading={isSaving}
                // loadingPosition="end"
                sx={{ maxWidth: 120, color: "#fff" }}
              >
                {t("Approve")}
              </LoadingButtonDX>
            )}
          {userData.userType == "S" &&
            inboundRequestStatus &&
            inboundRequestStatus == 3 &&
            isProcessInitiated &&
            isSkuDataModified && (
              <LoadingButtonDX
                color="secondary"
                onClick={() => onProcessClick(5)}
                loading={isSaving}
                sx={{ maxWidth: 180, color: "#fff" }}
              >
                {t("Send for Approval")}
              </LoadingButtonDX>
            )}

          {userData.userType == "C" &&
            inboundRequestStatus &&
            inboundRequestStatus == 5 && (
              <LoadingButtonDX
                color="error"
                onClick={onRejectModifications}
                loading={isSaving}
                // loadingPosition="end"
                sx={{ maxWidth: 200, color: "#fff" }}
              >
                {t("Reject Modifications")}
              </LoadingButtonDX>
            )}
          {userData.userType == "C" &&
            inboundRequestStatus &&
            inboundRequestStatus == 5 && (
              <LoadingButtonDX
                color="success"
                onClick={onApproveModifications}
                loading={isSaving}
                // loadingPosition="end"
                sx={{ maxWidth: 215, color: "#fff" }}
              >
                {t("Approve Modifications")}
              </LoadingButtonDX>
            )}

          {showSaveAndSubmit && (
            <LoadingButtonDX
              color="primary"
              onClick={() => onSaveClick(2)}
              loading={isSaving}
              // loadingPosition="end"
              sx={{ maxWidth: languageIsEn ? 180 : 240 }}
            >
              {t("Save & Submit")}
            </LoadingButtonDX>
          )}
        </GridDX>
      </GridDX>
      {open && (
        <ConfirmModal
          open={open}
          onYesClick={handleClose}
          onNoClick={() => setOpen(false)}
        />
      )}
      <GridDX
        container
        columnSpacing={1}
        rowSpacing={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          p: 2,
        }}
      >
        {props.children}
      </GridDX>
    </GridDX>
  );
};

export default AddEditEmbeddedModal;
