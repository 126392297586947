import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useSupplierService from "../../shared/services/supplierservice";

const Suppliers = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getSuppliers, archiveSupplier } = useSupplierService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "code",
      headerName: `${t("Code")}`,
      minWidth: 100,
    },
    {
      field: "pocName",
      headerName: `${t("Person")}`,
      minWidth: 150,
    },
    {
      field: "phone",
      headerName: `${t("Phone")}`,
      minWidth: 150,
    },
    {
      field: "streetAddress",
      headerName: `${t("Street Address")}`,
      minWidth: 250,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getSuppliers()
      .then((data) => {
        setDataFromApi(data);
        setRows(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    navigate("/supplierdetails", {
      state: {
        packageData: null,
      },
    });
  };
  const onEdit = (data: any) => {
    navigate(`/supplierdetails`, {
      state: {
        packageData: data,
      },
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveSupplier(id)
      .then(() => {
        setInfo(t("Supplier archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.code.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  return (
    <ListPageDX
      listTitle={t("Suppliers")}
      name={t("Suppliers")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.supplierId}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
      editLabel={t("Preview")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={false}
      showSelect={false}
    />
  );
};

export default Suppliers;
