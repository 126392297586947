import {
  AddOutlined,
  Archive,
  Edit,
  Inventory2,
  Inventory2Outlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import TypeTranslator from "../../../shared/typetranslator";
import ListPageDX from "../../business/listpagedx";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import InboundRequestItemForm from "../../units_forms/inboundrequests/inboundrequestitemform";
import CheckBoxDX from "../../controls/checkboxdx";
import TextFieldDX from "../../controls/textfielddx";
import placeholderskuimage5050 from "../../../assets/placeholderskuimage5050.png";
import InboundRequestSkuPlacementForm from "../../units_forms/inboundrequests/inboundrequestskuplacementform";

const InboundRequestProcessItems = (props: any) => {
  const { userData } = useAuthContext();
  const {
    requestData,
    setRequestData,
    getData,
    setIsChanged,
    skus,
    skuDropdownData,
    isFormEditable,
    isProcessInitiated,
    getSkuDropDownData,
  } = props;
  const [requestItemId, setRequestItemId] = useState(null);
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState<any>(requestData.inboundRequestSkus);
  const [items, setItems] = useState<any>(requestData.inboundRequestSkus);

  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();
  const { getPackagingTypeValue } = TypeTranslator();

  const handleCheckboxChange = (event: any, row: any) => {
    const updatedRows = rows.map((item: any) => {
      if (item.item_id === row.item_id) {
        return {
          ...item,
          receivedQuantity: event.target.checked ? item.quantity : 0,
        };
      }
      return item;
    });
    setRows(updatedRows);
    setRequestData({ ...requestData, inboundRequestSkus: updatedRows });
    setIsChanged(true);
  };

  const handleReceivedQuantityChange = (event: any, row: any) => {
    const updatedRows = rows.map((item: any) => {
      if (item.item_id === row.item_id) {
        return { ...item, receivedQuantity: event.target.value };
      }
      return item;
    });
    setRows(updatedRows);
    setRequestData({ ...requestData, inboundRequestSkus: updatedRows });
    setIsChanged(true);
  };
  const handlePackageQuantityChange = (event: any, row: any) => {
    const updatedRows = rows.map((item: any) => {
      if (item.item_id === row.item_id) {
        return { ...item, packageQuantity: event.target.value };
      }
      return item;
    });
    setRows(updatedRows);
    setRequestData({ ...requestData, inboundRequestSkus: updatedRows });
    setIsChanged(true);
  };

  const onDelete = (id: number) => {
    setRequestData((prev: any) => {
      const deletedItem = prev.inboundRequestSkus.find(
        (item: any) => item.item_id === id
      );
      const updatedItems = prev.inboundRequestSkus.filter(
        (item: any) => item.item_id !== id
      );
      const updatedData = {
        ...prev,
        inboundRequestSkus: [
          ...updatedItems,
          { ...deletedItem, isDeleted: true },
        ],
      };
      return updatedData;
    });
    setIsChanged(true);
    setInfo("Item deleted successfully");
  };

  const [showInboundItemForm, setShowInboundItemForm] = useState(false);
  const onEdit = (data: any) => {
    setRequestItemId(data.item_id);
    setShowInboundItemForm(true);
  };

  const toCreate = () => {
    setShowInboundItemForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowInboundItemForm(false);
    setRequestItemId(null);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(items);
      return;
    }
    const newRows = items.filter(
      ({ quantity, skuId, packagingTypeId }: any) => {
        return (
          quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
          getPackagingTypeValue(packagingTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          skus
            .find((s: any) => s.skuId === skuId)
            .name.toLowerCase()
            .includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    const filteredItems = requestData?.inboundRequestSkus?.filter(
      (item: any) => !item.isDeleted
    );
    setRows(filteredItems);
    setItems(filteredItems);
  }, [requestData.inboundRequestSkus]);

  const [showInboundPlacementForm, setShowInboundPlacementForm] =
    useState(false);
  const buildActions = (params: GridRowParams) => {
    let actionsArray: any = [];
    (isFormEditable ||
      (userData.userType == "S" &&
        requestData.status == 3 &&
        isProcessInitiated) ||
      requestData.status == 5) &&
      actionsArray.push(
        <GridActionsCellItem
          label={t("Place")}
          showInMenu
          onClick={() => {
            if (!params.row.receivedQuantity) {
              setInfo("Please enter received quantity");
              return;
            }
            if (!params.row.packageQuantity) {
              setInfo("Please enter package quantity");
              return;
            }
            setRequestItemId(params.row.item_id);
            setShowInboundPlacementForm(true);
          }}
          icon={<Inventory2 />}
        />
      );

    (isFormEditable ||
      (userData.userType == "S" &&
        requestData.status == 3 &&
        isProcessInitiated) ||
      requestData.status == 5) &&
      actionsArray.push(
        <GridActionsCellItem
          label={t("Edit")}
          showInMenu
          onClick={() => {
            onEdit(params.row);
          }}
          icon={<Edit />}
        />
      );
    (isFormEditable ||
      (userData.userType == "S" &&
        requestData.status == 3 &&
        isProcessInitiated)) &&
      actionsArray.push(
        <GridActionsCellItem
          label={t("Delete")}
          showInMenu
          onClick={() => {
            onDelete(params.row.item_id);
          }}
          icon={<Archive />}
        />
      );

    return actionsArray;
  };
  const columns: GridColDef[] = [
    {
      field: "fillReceivedQuantity",
      headerName: t(""),
      width: 10,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <CheckBoxDX
          // checked={params.row.receivedQuantity === params.row.quantity}
          onChange={(event: any) => handleCheckboxChange(event, params.row)}
        />
      ),
    },
    {
      field: "skuPictureURL",
      headerName: t("Image"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <BoxDX sx={{ p: 1 }}>
            <img
              src={
                skus.find((s: any) => s.skuId === params.row.skuId)
                  ?.skuPictureURL || placeholderskuimage5050
              }
              alt={params.row.skuId}
              style={{ width: 50, height: 50 }}
            />
          </BoxDX>
        );
      },
    },
    {
      field: "skuId",
      headerName: t("SKU"),
      flex: 1,
      valueGetter: (params: any) => {
        const sku = skus.find((s: any) => s.skuId === params.row.skuId);
        return sku ? sku.name : "";
      },
    },
    {
      field: "quantity",
      headerName: t("SKU Quantity"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "receivedQuantity",
      headerName: t("Received SKU Quantity"),
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <TextFieldDX
          type="number"
          value={params.row.receivedQuantity}
          onChange={(event: any) =>
            handleReceivedQuantityChange(event, params.row)
          }
          variant="outlined"
          size="small"
        />
      ),
    },
    {
      field: "packagingTypeId",
      headerName: t("Packaging Type"),
      flex: 1,
      valueGetter: (params: any) => {
        return getPackagingTypeValue(params.row.packagingTypeId);
      },
    },
    {
      // packagequantity
      field: "packageQuantity",
      headerName: t("Package Quantity"),
      flex: 1,
      renderCell: (params) => {
        return (
          <TextFieldDX
            type="number"
            value={params.row.packageQuantity}
            onChange={(event: any) =>
              handlePackageQuantityChange(event, params.row)
            }
            variant="outlined"
            size="small"
          />
        );
      },
    },
    //isPerishable
    {
      field: "isPerishable",
      headerName: t("Perishable"),
      flex: 1,
      valueGetter: (params: any) => {
        const sku = skus.find((s: any) => s.skuId === params.row.skuId);
        return sku?.isPerishable ? t("Yes") : t("No");
      },
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: buildActions,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  return (
    <>
      {showInboundItemForm && (
        <InboundRequestItemForm
          requestData={requestData}
          requestItemId={requestItemId}
          setRequestData={setRequestData}
          handleClose={onClose}
          setIsChanged={setIsChanged}
          skus={skus}
          skuDropdownData={skuDropdownData}
          getSkuDropDownData={getSkuDropDownData}
        />
      )}
      {showInboundPlacementForm && (
        <InboundRequestSkuPlacementForm
          requestItemId={requestItemId}
          requestData={requestData}
          onClose={() => setShowInboundPlacementForm(false)}
          skus={skus}
          warehouseId={requestData.warehouseId}
        />
      )}

      <GridDX container xs={12}>
        <GridDX item xs={12} justifyContent="space-between">
          <TypographyDX variant="h5" fontWeight="bold">
            {t("Items")}
          </TypographyDX>
          {(isFormEditable ||
            (userData.userType == "S" &&
              requestData.status == 3 &&
              isProcessInitiated)) && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "#fff", wordSpacing: 4 }}
              onClick={toCreate}
            >
              <AddOutlined /> {t("Add Item")}
            </Button>
          )}
        </GridDX>
        <GridDX item xs={12} sx={{ mb: 2 }}>
          <ListPageDX
            listTitle={t("Item")}
            name={t("Item")}
            rows={rows}
            columns={columns}
            getRowId={(row: any) => row.item_id}
            exportToPDF={false}
            exportToCSV={false}
            exportDropdown={false}
            isLoading={isLoading}
            toCreate={toCreate}
            // // enabled (for admin only) if the request is approved or in draft
            // onEdit={
            //   (isFormEditable ||
            //     (userData.userType == "S" &&
            //       requestData.status == 3 &&
            //       isProcessInitiated) ||
            //     requestData.status == 5) &&
            //   onEdit
            // }
            // onDelete={
            //   (isFormEditable ||
            //     (userData.userType == "S" &&
            //       requestData.status == 3 &&
            //       isProcessInitiated)) &&
            //   onDelete
            // }
            setGridFilterCriteria={setGridFilterCriteria}
            rowClick={null}
          />
        </GridDX>
      </GridDX>
    </>
  );
};

export default InboundRequestProcessItems;
