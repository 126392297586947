import useSecureAPI from "../hooks/usesecureapi";

const useBrandService = () => {
  const secureAPI = useSecureAPI();

  const getBrands = async () => {
    const response = await secureAPI.get("Brand");
    return response.data;
  };

  const getBrandById = async (id: number) => {
    const response = await secureAPI.get(`Brand/${id}`);
    return response.data;
  };

  const addBrand = async (data: any) => {
    const response = await secureAPI.post("Brand", data);
    return response.data;
  };

  const updateBrand = async (id: number, data: any) => {
    const response = await secureAPI.put(`Brand`, data);
    return response.data;
  };

  const archiveBrand = async (id: number) => {
    const response = await secureAPI.put(`Brand/archive/${id}`);
    return response.data;
  };

  return {
    getBrands,
    getBrandById,
    addBrand,
    updateBrand,
    archiveBrand,
  };
};

export default useBrandService;
