import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DataGridDX from "../../layout/datagriddx";
import { GridColDef, GridSlotsComponentsProps } from "@mui/x-data-grid";
import ButtonDX from "../../controls/buttondx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import SelectListDX from "../../controls/selectlistdx";
import useWarehouseRowsService from "../../../shared/services/warehouserowservice";
import useWarehouseBayService from "../../../shared/services/warehousebayservice";
import useWarehouseLevelService from "../../../shared/services/warehouselevelsservice";
import BoxDX from "../../layout/boxdx";
import TypographyDX from "../../layout/typographydx";

const InboundRequestSkuPlacementForm = (props: any) => {
  const { userData } = useAuthContext();
  const { requestItemId, requestData, skus, warehouseId } = props;
  const { getWarehouseById } = useWarehouseService();
  const { getWarehouseAreasByWarehouseId } = useWarehouseAreaService();
  const { getWarehouseRowsByAreaId } = useWarehouseRowsService();
  const { getWarehouseBaysByRowId } = useWarehouseBayService();
  const { getWarehouseLevelsByBayId } = useWarehouseLevelService();
  const { t } = useTranslation();

  const [rows, setRows] = useState<any>([]);
  const [warehouseAreas, setWarehouseAreas] = useState<any>([]);
  const [warehouseRows, setWarehouseRows] = useState<any>([]);
  const [warehouseBays, setWarehouseBays] = useState<any>([]);
  const [warehouseLevels, setWarehouseLevels] = useState<any>([]);

  const getData = async () => {
    getWarehouseAreasByWarehouseId(warehouseId)
      .then((warehouseAreas) => {
        setWarehouseAreas(
          warehouseAreas.map((wa: any) => ({
            text: wa.name,
            value: wa.warehouseAreaId,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });

    if (requestItemId) {
      const item = requestData.inboundRequestSkus.find(
        (item: any) => item.item_id === requestItemId
      );
      if (item) {
        console.log("item", item);
        const rows = [];
        const packageQuantity = item.packageQuantity;
        const receivedQuantity = item.receivedQuantity;
        const inboundRequestId = item.inboundRequestId
          .toString()
          .padStart(7, "0");
        let remainingQuantity = receivedQuantity;

        while (remainingQuantity > 0) {
          const quantity = Math.min(packageQuantity, remainingQuantity);
          rows.push({
            id: `${inboundRequestId}-${item.inboundRequestSkuId}-${
              rows.length + 1
            }`,
            skuId: item.skuId,
            quantity: quantity,
            capacity: packageQuantity,
            warehouseId: warehouseId,
            warehouseAreaId: "",
            warehouseRowId: "",
            warehouseBayId: "",
            warehouseLevelId: "",
          });
          remainingQuantity -= quantity;
        }

        rows.push({
          id: "TOTAL",
          quantity: receivedQuantity,
          capacity: "",
          warehouseId: warehouseId,
          warehouseAreaId: "",
          warehouseRowId: "",
          warehouseBayId: "",
          warehouseLevelId: "",
        });

        setRows(rows);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const loadWarehouseRows = async (warehouseAreaId: any) => {
    try {
      if (warehouseRows[warehouseAreaId]) {
        return;
      }
      const warehouseRow = await getWarehouseRowsByAreaId(warehouseAreaId);
      setWarehouseRows((prevState: any) => ({
        ...prevState,
        [warehouseAreaId]: warehouseRow.map((wr: any) => ({
          text: wr.number,
          value: wr.warehouseRowId,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const loadWarehouseBays = async (warehouseRowId: any) => {
    try {
      if (warehouseBays[warehouseRowId]) {
        return;
      }
      const warehouseBay = await getWarehouseBaysByRowId(warehouseRowId);
      setWarehouseBays((prevState: any) => ({
        ...prevState,
        [warehouseRowId]: warehouseBay.map((wb: any) => ({
          text: wb.number,
          value: wb.warehouseBayId,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const loadWarehouseLevels = async (warehouseBayId: any) => {
    try {
      if (warehouseLevels[warehouseBayId]) {
        return;
      }
      const warehouseLevel = await getWarehouseLevelsByBayId(warehouseBayId);
      setWarehouseLevels((prevState: any) => ({
        ...prevState,
        [warehouseBayId]: warehouseLevel.map((wl: any) => ({
          text: wl.number,
          value: wl.warehouseLevelId,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleWarehouseAreaChange = (e: any, row: any) => {
    const updatedRows = [...rows];
    const index = updatedRows.findIndex((r: any) => r.id === row.id);
    updatedRows[index].warehouseAreaId = e.target.value;
    updatedRows[index].warehouseRowId = ""; // Reset warehouseRowId when warehouseAreaId changes
    setRows(updatedRows);
    loadWarehouseRows(e.target.value); // Load the warehouse rows for the selected area
  };

  const handleWarehouseRowChange = (e: any, row: any) => {
    const updatedRows = [...rows];
    const index = updatedRows.findIndex((r: any) => r.id === row.id);
    updatedRows[index].warehouseRowId = e.target.value;
    setRows(updatedRows);
    loadWarehouseBays(e.target.value); // Load the warehouse bays for the selected row
  };

  const handleWarehouseBayChange = (e: any, row: any) => {
    const updatedRows = [...rows];
    const index = updatedRows.findIndex((r: any) => r.id === row.id);
    updatedRows[index].warehouseBayId = e.target.value;
    setRows(updatedRows);
    loadWarehouseLevels(e.target.value); // Load the warehouse levels for the selected bay
  };

  const handleWarehouseLevelChange = (e: any, row: any) => {
    const updatedRows = [...rows];
    const index = updatedRows.findIndex((r: any) => r.id === row.id);
    updatedRows[index].warehouseLevelId = e.target.value;
    setRows(updatedRows);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("Box ID"),
      minWidth: 170,
    },
    {
      field: "quantity",
      headerName: t("Quantity"),
    },
    {
      field: "warehouseAreaId",
      headerName: t("Area"),
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.id === "TOTAL") {
          return <span style={{ height: 50 }}></span>;
        }
        return (
          <SelectListDX
            sx={{
              my: 0.5,
            }}
            name="warehouseAreaId"
            label={t("Area")}
            items={warehouseAreas}
            value={params.row.warehouseAreaId}
            onChange={(e: any) => handleWarehouseAreaChange(e, params.row)}
          />
        );
      },
    },
    {
      field: "warehouseRowId",
      headerName: t("Row"),
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.id === "TOTAL") {
          return null;
        }
        return (
          <SelectListDX
            sx={{
              my: 0.5,
            }}
            name="warehouseRowId"
            label={t("Row")}
            items={warehouseRows[params.row.warehouseAreaId] || []}
            value={params.row.warehouseRowId}
            onChange={(e: any) => handleWarehouseRowChange(e, params.row)}
          />
        );
      },
    },
    {
      field: "warehouseBayId",
      headerName: t("Bay"),
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.id === "TOTAL") {
          return null;
        }
        return (
          <SelectListDX
            sx={{
              my: 0.5,
            }}
            name="warehouseBayId"
            label={t("Bay")}
            items={warehouseBays[params.row.warehouseRowId] || []}
            value={params.row.warehouseBayId}
            onChange={(e: any) => handleWarehouseBayChange(e, params.row)}
          />
        );
      },
    },
    {
      field: "warehouseLevelId",
      headerName: t("Level"),
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.id === "TOTAL") {
          return null;
        }
        return (
          <SelectListDX
            sx={{
              my: 0.5,
            }}
            name="warehouseLevelId"
            label={t("Level")}
            items={warehouseLevels[params.row.warehouseBayId] || []}
            value={params.row.warehouseLevelId}
            onChange={(e: any) => handleWarehouseLevelChange(e, params.row)}
          />
        );
      },
    },
  ];

  return (
    <Dialog open={true} fullWidth maxWidth="xl">
      <DialogTitle sx={{ fontSize: "24px" }}>
        {t("Inbound Request SKU Placement")}
      </DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <DataGridDX
          listTitle={t("Item")}
          name={t("Item")}
          rows={rows}
          columns={columns}
          checkboxSelection={false}
          hideFooter={true}
          rowsPerPage={rows.length}
        />
      </DialogContent>
      <DialogActions>
        <ButtonDX
          onClick={() => {
            props.onClose();
          }}
        >
          {t("Close")}
        </ButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default InboundRequestSkuPlacementForm;
